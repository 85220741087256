<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        v-if="loggedIn"
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn
        v-else
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list
      v-if="loggedIn"
      :tile="false"
      flat
      nav
    >
      <template v-for="(p, i) in profile">
        <v-divider
          v-if="p.divider"
          :key="`divider-${i}`"
          class="mb-2 mt-2"
        />
        <app-bar-item
          v-else
          :key="`item-${i}`"
          :to="p.path"
        >
          <template v-if="p.method">
            <v-list-item-title
              @click="menuActionClick(p.method)"
              v-text="p.title"
            />
          </template>
          <template v-else>
            <v-list-item-title
              v-text="p.title"
            />
          </template>
        </app-bar-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import { authComputed } from '../../../store/helpers'
  export default {
    name: 'DefaultAccount',

    data: () => ({
      testVar: 'init value',
      profile: [
        {
          title: 'Profile',
          path: { name: 'Account/View' },
        },
        {
          title: 'Settings',
          path: { name: 'Account/View' },
        },
        { divider: true },
        {
          title: 'Log out',
          method: 'logout',
        },
      ],
    }),
    computed: {
      ...authComputed,
    },
    methods: {
      logout () {
        this.$store.dispatch('auth/logout')
      },
      menuActionClick (action) {
        if (action === 'logout') {
          this.logout()
        }
      },
    },
  }
</script>
